import "hacktimer/HackTimer";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { store } from "redux/store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import themeConfig from "configs/themeConfig";
import Router from "router/Router";

import "./index.css";

import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import Maintenance from "views/features/exception/pages/Maintenance";

if (
  process.env.REACT_APP_ENV !== "local" &&
  window.location.origin.includes("zenhire.ai")
)
  Sentry.init({
    dsn: "https://86dc14e31ddcbdf8319f2866f91340b9@o4506008901386240.ingest.sentry.io/4506008924979200",
    release: `zenhire-app@1.0.1`,
    tunnel: `${process.env.REACT_APP_BASENAME}/sentry/tunnel`,
    environment: `${process.env.REACT_APP_ENV}`,
    initialScope: {
      tags: { user: "initial", application: "-" },
      user: { id: "undefined", username: "unregistered" },
    },
    tracePropagationTargets: ["localhost", `${process.env.REACT_APP_BASENAME}`],
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: process.env.REACT_APP_ENV === "production" ? 0.5 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // Uncomment the following line to see the maintenance page
  // <Maintenance />

  // Comment the following lines to render the app
  <Sentry.ErrorBoundary fallback={<p>An error has occurred in the app</p>}>
    <Provider store={store}>
      <Router />

      <Toaster
        position={themeConfig.layout.toastPosition}
        toastOptions={{ className: "react-hot-toast", duration: 7000 }}
      />
    </Provider>
  </Sentry.ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
