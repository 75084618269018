import React, { useEffect, useState } from "react";
import UploadDropzone from "views/components/UploadInput/UploadDropzone";
import { ReactComponent as CsvIco } from "assets/icons/csv-ico.svg";
import { useParams } from "react-router";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/reduxTypes";
import {
  downloadPositionTemplate,
  importPositionTemplate,
} from "../../reducer/position.actions";
import Spinner from "../../../../components/spinners/Spinner";
import ModalWithContent from "../../../../components/ModalWithContent/ModalWithContent";
import CustomNote from "../../../../components/CustomNote/CustomNote";
import SingleSelect from "../../../../components/Inputs/SingleSelect/SingleSelect";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { ReactComponent as InfoIco } from "assets/icons/info-ico.svg";

type inputType = {
  error?: boolean;
  source?: string;
};

const initialInput: inputType = {
  error: false,
  source: "",
};

const ImportApplicantsForm = ({
  close,
  refreshFunction,
}: {
  close?: () => void;
  refreshFunction?: any;
}) => {
  const [input, setInput] = useState<inputType>(initialInput);
  const [file, setFile] = useState<File | null>(null);
  const key = useParams();
  const dispatch = useAppDispatch();
  const downloadLoading = useAppSelector(
    (state) => state.position.downloadTemplate.loading,
  );
  const importLoading = useAppSelector(
    (state) => state.position.importTemplate.loading,
  );
  const position = useAppSelector(
    (state) => state.position.positionOverview?.value,
  );
  const positionOverview = useAppSelector(
    (state) => state.position.positionOverview?.value,
  );

  const [sourceOptions, setSourceOptions] = useState<any>([
    {
      label: "A friend sent it to me",
      value: "friend",
    },
    {
      label: "Other",
      value: "other",
    },
  ]);

  useEffect(() => {
    const sources = positionOverview?.sources;
    if (sources) {
      const newOptions = sources?.map((v: { name: string; slug: string }) => {
        return { label: v.name, value: v.slug };
      });
      setSourceOptions([...newOptions, ...sourceOptions]);
    }
  }, [positionOverview?.sources]);

  async function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    if (file && key.id) {
      dispatch(
        importPositionTemplate({
          positionId: key.id,
          file,
          ...(input.source && {
            source: input.source,
          }),
        }),
      ).then((res) => {
        if (close) {
          close();
        }
        if (refreshFunction && res.payload) {
          refreshFunction();
        }
      });
    } else {
      setInput({ ...input, error: true });
    }
  }
  const handleUploadFile = (file: File) => {
    setFile(file);
  };

  const handleDownloadTemplate = () => {
    if (!key.id) return;
    dispatch(downloadPositionTemplate({ positionId: key.id })).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload as any]));

      const link = document.createElement("a");
      link.href = url;
      link.download = position?.name
        ? `${position.name.toLowerCase().replace(/ /g, "-")}-template.csv`
        : "template-position.csv";

      document.body.appendChild(link);

      link.click();
      link.remove();
    });
  };

  return (
    <>
      <ModalWithContent
        close={() => (close ? close() : null)}
        title={"Import Candidates"}
        lightTitle={""}
        actionButton={{
          text: `Confirm`,
          onSubmit: () => handleSubmit(),
          processing: importLoading,
          disabled: importLoading || !file,
        }}
        modalClassName={"!w-[500px] max-h-[90%]"}
      >
        <div
          className={"flex flex-col gap-3 mt-5"}
          onClick={() => handleDownloadTemplate()}
        >
          <p className="text-sm text-z-grey-600 -mt-6 font-light zmd:text-center zsm:-mb-4">
            Download the following CSV template and fill it out with your
            candidates:
          </p>
          <div
            className={`hover:bg-z-grey-150 cursor-pointer rounded-[10px] p-[10px] bg-z-grey-100
                            w-full text-main-color text-base flex justify-start items-center gap-2 ${downloadLoading ? "select-none opacity-80" : ""}`}
          >
            <CsvIco className={"[&_path]:fill-main-color w-[25px]"} />
            Download CSV Template
            {downloadLoading ? (
              <Spinner classIcon={"w-[15px] h-[15px]"} />
            ) : null}
          </div>
          <hr className=" mt-3 mb-4 border-1 border-z-grey-200 w-full" />
        </div>
        <div className="flex flex-col gap-6 w-full">
          <div className="flex flex-col items-start">
            <p className="text-dark-gray text-sm font-light mb-3">
              Once you have the template filled out, drop it here:
            </p>
            <UploadDropzone
              accept={["csv"]}
              onChange={handleUploadFile}
              onRemove={() => setFile(null)}
              invalid={!file && input.error}
              placeholder={"Drop your CSV file here or "}
              maxFiles={50}
            />
          </div>
          <div className={"flex flex-col gap-2 w-full"}>
            <div className={"text-sm text-[#838383] flex gap-2 items-center"}>
              Candidates Source
              <Tooltip
                toggler={<InfoIco className="[&_path]:fill-main-color" />}
                content={
                  <>
                    <p className="text-white font-light">
                      Select the source from which the candidates originated.
                    </p>
                    <p className="text-white font-light">
                      This helps track and filter candidates by source,
                      providing valuable analytics.
                    </p>
                    <p className="text-white font-light">
                      If the candidate is not from any of the listed platforms
                      or is irrelevant, leave it as 'manual import'.
                    </p>
                  </>
                }
              />
            </div>
            <SingleSelect
              containerClassName={"!w-full"}
              data={sourceOptions}
              selectedValue={sourceOptions?.find(
                (item: { value: string }) => item.value === input.source,
              )}
              onChange={(newValue) =>
                setInput({
                  ...input,
                  source: newValue?.value,
                })
              }
            />
          </div>
          <CustomNote className="" type={"warning"}>
            The CSV file <b className={"font-medium"}>cannot</b> contain more
            than 50 candidates.
          </CustomNote>
        </div>
      </ModalWithContent>
    </>
  );
};

export default ImportApplicantsForm;
