import MergeLayoutRoutes from "./MergeLayoutRoutes";

// ** Routes Imports
import authRoutes from "views/features/auth/routes/index";
import dashboardRoutes from "views/features/dashboard/routes";
import exceptionRoutes from "views/features/exception/routes";
import testLibraryRoutes from "views/features/testLibrary/routes";
import microdimensionsRoutes from "views/features/microdimensions/routes";
import usersRoutes from "views/features/users/routes";
import applicantRoutes from "views/features/applicant/routes";
import applicantsRoutes from "views/features/applicants/routes";
import allApplicationRoute from "views/features/application/routes";
import positionsRoutes from "views/features/positions/routes";
import allPositionRoute from "views/features/position/routes";
import companiesRoute from "../../views/features/companies/routes";
import companyAuthRoutes from "../../views/features/company/companyAuth/routes";
import companyDashboardRoutes from "../../views/features/company/companyDashboard/routes";
import systemEmails from "views/features/systemEmails/routes";
import { filterCompanyRoutes } from "router/helpers/router.functions";
import inboxRoutes from "views/features/inbox/routes";
import applicationsRoutes from "../../views/features/applications/routes";
import supportRoutes from "../../views/features/support/routes/index";

const { positionRoutes, positionTemplatesRoutes } = allPositionRoute;
const { applicationRoutes, applicationTemplatesRoute } = allApplicationRoute;

// import all admin routes
const adminRoutes = [
  ...authRoutes,
  ...exceptionRoutes,
  ...dashboardRoutes,
  ...testLibraryRoutes,
  ...microdimensionsRoutes,
  ...usersRoutes,
  ...applicantRoutes,
  ...applicantsRoutes,
  ...applicationsRoutes,
  ...applicationRoutes,
  ...positionsRoutes,
  ...companiesRoute,
  ...systemEmails,
  ...positionTemplatesRoutes,
  ...applicationTemplatesRoute,
  ...supportRoutes,
];

// import all company only routes
const companyOnlyRoutes = [
  ...companyAuthRoutes,
  ...companyDashboardRoutes,
  ...positionRoutes,
  ...positionTemplatesRoutes,
  ...applicationTemplatesRoute,
  ...applicationsRoutes,
  ...inboxRoutes,
];

// prepare all company routes
const companyRoutes = [
  ...filterCompanyRoutes([...adminRoutes, ...companyOnlyRoutes]),
];

// all routes
const routes = [...adminRoutes, ...companyRoutes];

const getRoutes = () => {
  return [
    // here are all routes available in the app
    ...MergeLayoutRoutes(routes),
  ];
};

export { routes, getRoutes };
