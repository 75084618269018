import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const Support: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/Support"))
);

const supportRoutes: routeType[] = [
  {
    path: "/support",
    element: <Support />,
    meta: {
      layout: "app",
      title: "Support",
      type: "page",
      private: true,
      company: true,
    },
  },
];

export default supportRoutes;
