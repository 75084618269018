import Spinner from "../spinners/Spinner";

const PageLoadingSpinner = () => {

    return (
        <div className="h-full flex flex-col items-center justify-center">
            <Spinner />
        </div>
    )
}

export default PageLoadingSpinner