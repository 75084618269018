import Button from "../Button/Button";
import { ReactNode } from "react";

interface propTypes {
  close?: () => void;
  additionalButton?: {
    text: string;
    processing?: boolean;
    disabled?: boolean;
    onSubmit: () => void;
  };
  actionButton?: {
    text?: string;
    processing?: boolean;
    disabled?: boolean;
    onSubmit?: () => void;
    className?: string;
    submitIco?: any;
  };
  containerClassName?: string;
  customRef?: any;
  hideCloseButton?: boolean;
  closeButtonText?: string;
  additionalButtonChildren?: ReactNode;
  additionaButtonEnableSaveChanges?: {
    hasChanges?: boolean;
    show: () => void;
  };
}

const ModalFooter = ({
  close,
  actionButton,
  additionalButton,
  containerClassName,
  customRef,
  hideCloseButton,
  closeButtonText,
  additionalButtonChildren,
  additionaButtonEnableSaveChanges,
}: propTypes) => {
  return (
    <div
      ref={customRef || null}
      className={`rounded-b-2xl bg-white border-t border-1 border-[#D6D6D6] px-[17px] py-[10px] fixed bottom-0 w-full z-[10] flex gap-3 items-center justify-between ${containerClassName || ""}`}
    >
      <div>
        {/* third option here */}
        {additionalButton?.onSubmit && (
          <Button
            type="Naked"
            text={additionalButton.text}
            state={additionalButton.processing ? "Loading" : ""}
            disabled={additionalButton.processing || additionalButton.disabled}
            onClick={() =>
              additionaButtonEnableSaveChanges?.hasChanges
                ? additionaButtonEnableSaveChanges?.show()
                : additionalButton.onSubmit()
            }
          />
        )}
        {additionalButtonChildren ? additionalButtonChildren : null}
      </div>
      <div className={"flex gap-3 items-center justify-end"}>
        {close && !hideCloseButton && (
          <Button
            type="Secondary"
            text={closeButtonText || "Cancel"}
            onClick={() => close()}
            className={`xl:bg-transparent ${actionButton?.className ? actionButton?.className : ""} !font-medium !min-w-[140px]`}
          />
        )}
        {actionButton?.onSubmit && (
          <Button
            type="Primary"
            state={actionButton.processing ? "Loading" : ""}
            disabled={actionButton.processing || actionButton.disabled}
            text={actionButton.text || "Confirm"}
            onClick={() =>
              actionButton.onSubmit ? actionButton.onSubmit() : null
            }
            className={`${actionButton.className || ""} !min-w-[140px]`}
            FunctionIcon={actionButton?.submitIco}
          />
        )}
      </div>
    </div>
  );
};

export default ModalFooter;
