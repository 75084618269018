import Modal from "../../../../components/Modal/Modal";
import { FunctionComponent, useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import SingleSelect from "../../../../components/Inputs/SingleSelect/SingleSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/reduxTypes";
import { useLocation } from "react-router";
import NumberInput from "../../../../components/Inputs/NumberInput/NumberInput";
import { setGlobalFilters } from "../../../../../redux/globalFilters";
import ModalWithContent from "../../../../components/ModalWithContent/ModalWithContent";

interface propsTypes {
  handleCloseModal: () => void;
  title: string;
}

const filtersOptions = [
  {
    label: "Fixed amount of Top Applicants",
    value: "top",
    description: "For example: only the top 10 Applicants will be shown",
  },
  {
    label: "Relative amount of Top Applicants",
    value: "topPercentage",
    description: "For example: only the top 20% Applicants will be shown",
  },
  {
    label: "All Applicants over a certain Step Score",
    value: "threshold",
    description:
      "For example: only Applicants with a step score over 80% will be shown",
  },
];

const formatOptionLabel = ({
  label,
  description,
}: {
  label: string;
  description: number;
}) => (
  <span className={"text-black font-[500] cursor-pointer group"}>
    {label}
    <span className={"text-z-grey-450 block font-[400]"}>{description}</span>
  </span>
);

type typeSelect = {
  label: string;
  value: string;
  description: string;
};
const CandidateListModal: FunctionComponent<propsTypes> = (props) => {
  const { handleCloseModal, title } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const globalFilters: any = useAppSelector(
    (state) => state.globalFilters[location.pathname],
  );
  const [value, setValue] = useState<typeSelect | null>(null);
  const [scoreValue, setScoreValue] = useState<number>(1);
  const [scoreError, setScoreError] = useState<boolean>(false);

  const [hasChanges, setHasChanges] = useState(false);
  const checkEqual =
    globalFilters?.filters?.scoreType === value?.value &&
    globalFilters?.filters?.scoreValue === scoreValue;
  useEffect(() => {
    setHasChanges(checkEqual);
  }, [value, checkEqual, scoreValue]);

  const handleSelect = (e: typeSelect) => {
    setValue(e);
    const scoreExample =
      e?.value === "top" ? 10 : e?.value === "topPercentage" ? 20 : 80;
    setScoreValue(scoreExample);
  };

  const handleSaveFilters = () => {
    handleCloseModal();
    if (value?.value) {
      dispatch(
        setGlobalFilters({
          location: location.pathname,
          filters: {
            ...globalFilters?.filters,
            scoreType: value?.value,
            scoreValue: scoreValue,
          },
          page: 1,
        }),
      );
    } else {
      const { score, ...newFilters } = globalFilters?.filters;
      dispatch(
        setGlobalFilters({
          location: location.pathname,
          filters: newFilters,
          page: 1,
        }),
      );
    }
  };

  useEffect(() => {
    if (globalFilters?.filters?.scoreType) {
      setValue(
        filtersOptions.find(
          (item: any) => item.value === globalFilters?.filters?.scoreType,
        ) || null,
      );
      setScoreValue(globalFilters?.filters?.scoreValue);
    }
  }, [globalFilters?.filters]);

  const handleChangeNumber = (score: number) => {
    if (score < 1 || (score > 100 && value?.value !== "top")) {
      setScoreError(true);
    } else {
      setScoreError(false);
      setScoreValue(score);
    }
  };

  return (
    <ModalWithContent
      close={() => handleCloseModal()}
      title={title}
      actionButton={{
        text: `Save`,
        onSubmit: () => handleSaveFilters(),
        disabled:
          (value && (scoreValue === null || scoreValue < 1)) ||
          (!globalFilters?.filters?.scoreType && !value) ||
          hasChanges ||
          scoreError,
      }}
      modalClassName={"!w-[600px] max-h-[90%]"}
    >
      <div className={"zh-progress-table-filter-by-score"}>
        <SingleSelect
          label={"Type of Criteria"}
          data={filtersOptions}
          formatOptionLabel={formatOptionLabel}
          selectedValue={
            value ? { label: value?.label, value: value?.value } : null
          }
          onChange={(e) => handleSelect(e)}
          filterScore
          isClearable
          isSearchable={false}
        />
      </div>
      {value ? (
        <div className={"mt-5"}>
          <NumberInput
            label={`Score Value ${value.value !== "top" ? "(%)" : ""}`}
            placeholder={value.description}
            onChange={(value) => handleChangeNumber(value)}
            value={scoreValue}
          />
        </div>
      ) : null}
    </ModalWithContent>
  );
};

export default CandidateListModal;
