import { http } from "common/api/http";
import { microdimensionParamsType } from "../microdimensions.types";
export async function fetchMicrodimensions({
  page,
  category,
  limit,
  search,
  sort,
  direction,
  custom,
  companyId,
  signal,
}: microdimensionParamsType) {
  const params = {
    limit: limit ? limit : 50,
    page: page ? page : 1,
    ...(category && {
      category: category,
    }),
    ...(search && {
      search: search,
    }),
    ...(sort && {
      sort: `${direction === "desc" ? "-" : ""}${sort}`,
    }),
    custom: custom,
    ...(companyId && {
      company: companyId,
    }),
  };

  return await http.get(`/microdimensions`, { params, signal });
}
